<template>
    <div class="page-content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="
              page-title-box
              d-sm-flex
              align-items-center
              justify-content-between
            ">
                        <h4 class="mb-sm-0">Bagi Hasil</h4>

                        <div class="page-title-right">
                            <ol class="breadcrumb m-0">
                                <li class="breadcrumb-item active">Daftar Bagi Hasil</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xl-12">

                    <div class="card">
                        <div class="card-body">
                            <div v-if="!load_period">
                                <div v-if="share_period">
                                    <div v-if="!transaction_success">
                                        <form @submit.prevent="submit">
                                            <div class="alert alert-primary text-center mb-3" role="alert">
                                                <p>1 slot saham Inalife ( berisi 10 lembar saham ) <b>{{
                                                        share_period['name']
                                                }}</b> seharga
                                                    <b>{{ toIdr(share_period['price']) }}-</b>
                                                </p>
                                            </div>

                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="alert alert-success text-center mb-3" role="alert">
                                                        <p>Total Harga</p>
                                                        <h3><b>{{ toIdr(price_total) }}</b></h3>
                                                        <p>Lakukan pembayaran ke nomor reking
                                                            <strong>646401032252538</strong> atas nama
                                                            <strong>INALIFE
                                                                INDONESIA</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="mb-3">
                                                        <label class="form-label">Jumlah Slot</label>
                                                        <div
                                                            class="input-group bootstrap-touchspin bootstrap-touchspin-injected mb-3">
                                                            <span class="input-group-btn input-group-prepend">
                                                                <button class="btn btn-primary bootstrap-touchspin-down"
                                                                    type="button" @click.prevent="minSLot()">-</button>
                                                            </span>
                                                            <input type="number" v-model="share_purchase.number_of_slot"
                                                                class="form-control">
                                                            <span class="input-group-btn input-group-append">
                                                                <button class="btn btn-primary bootstrap-touchspin-up"
                                                                    type="button" @click.prevent="addSLot()">+</button>
                                                            </span>
                                                        </div>

                                                        <div class="mb-3">
                                                            <label class="form-label">Bukti Transfer</label>
                                                            <input type="file" class="form-control"
                                                                accept="image/png, image/jpeg, image/jpg"
                                                                @change="transferChange($event)" required />
                                                            <small class="text-muted">Max. 10MB (Jika melebihi tidak
                                                                akan
                                                                terupload dan proses
                                                                verifikasi
                                                                akan
                                                                lebih lama)</small>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="mt-4 text-center">
                                                <button :disabled="load" class="btn btn-success" type="submit">
                                                    <i class="bx font-size-16 align-middle me-2" :class="{
                                                        'bx-loader bx-spin': load,
                                                        'bx-send': !load,
                                                    }"></i>
                                                    Kirim Pengajuan Pembelian
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="text-center py-5" v-else>
                                        <div class="mb-4">
                                            <lord-icon src="https://cdn.lordicon.com/lupuorrc.json" trigger="loop"
                                                colors="primary:#0ab39c,secondary:#405189"
                                                style="width:120px;height:120px">
                                            </lord-icon>
                                        </div>
                                        <h5>Selamat! Pengajuan Pembelian Saham Anda Berhasil Dikirim!</h5>

                                        <p class="text-muted">Kami akan segera melakukan verifikasi transaksi dan akan
                                            mengirimkan notifikasi melalui email.</p>

                                        <button @click="newOrder()" type="button"
                                            class="btn btn-primary waves-effect waves-light mt-4 me-2"><i
                                                class="bx bx-cart font-size-16 align-middle me-2"></i>
                                            Buat Pembelian Baru
                                        </button>

                                        <router-link :to="{ name: 'user.share-purchase-history' }" type="button"
                                            class="btn btn-success waves-effect waves-light mt-4"><i
                                                class="bx bx-cart font-size-16 align-middle me-2"></i>
                                            Lihat Detail Transaksi
                                        </router-link>
                                    </div>
                                </div>
                                <div class="text-center py-5" v-else>
                                    <div class="mb-4">
                                        <lord-icon src="https://cdn.lordicon.com/tyounuzx.json" trigger="loop"
                                            colors="primary:#0ab39c,secondary:#405189" style="width:120px;height:120px">
                                        </lord-icon>
                                    </div>
                                    <h5>Pembelian Saham Sedang Tidak Tersedia!</h5>

                                    <p class="text-muted">Silahkan kembali saat pemebelian saham periode salanjutnya
                                        dibuka.</p>
                                </div>
                            </div>
                            <div v-else>
                                <FormShimmer :count="5" />
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { endpoint } from "../../host";
import DataTable from "../../components/datatable/DataTable.vue";
import httpService from "../../services/http-service";
import globalService from '../../services/global-service';
import errorService from "../../services/error-service";
import axios from "axios";
import FormShimmer from "../../components/shimmer/FormShimmer.vue";

export default {
    components: {
        FormShimmer
    },

    created() {
        this.getActiveSharePeriod();
    },

    data() {
        return {
            share_period: null,
            load: false,
            load_period: false,
            transaction_success: false,
            price_total: 0,
            share_purchase: {
                share_period_id: null,
                number_of_slot: 0,
                evidence_of_transfer: null,
            }
        };
    },

    methods: {
        async submit() {
            try {
                this.load = true;
                let formData = new FormData();
                for (const key in this.share_purchase) {
                    formData.append(key, this.share_purchase[key] ?? "");
                }
                let r = await httpService.post(endpoint["share.purchase"], formData);
                this.load = false;
                if (r.status == 200) {
                    this.transaction_success = true;
                }
            } catch (error) {
                this.load = false;
                errorService.displayError(error);
            }
        },

        async getActiveSharePeriod() {
            this.load_period = true;
            let res = await httpService.get(httpService.generateEndpointQuery(endpoint['share.period'], {
                is_active: '1',
            }));
            if (res.data.data.length > 0) {
                this.share_period = res.data.data[0];
                this.share_purchase.share_period_id = this.share_period['id'];
            }
            this.load_period = false;
        },

        minSLot() {
            if (this.share_purchase.number_of_slot > 1) {
                this.share_purchase.number_of_slot--;
            }
            this.price_total = this.share_purchase.number_of_slot * this.share_period['price'];
        },

        addSLot() {
            if (this.share_purchase.number_of_slot < 100) {
                this.share_purchase.number_of_slot++;
            }
            this.price_total = this.share_purchase.number_of_slot * this.share_period['price'];
        },

        transferChange(event) {
            this.share_purchase.evidence_of_transfer = event.target.files[0];
        },

        toIdr(a) {
            return globalService.toIdr(a);
        },

        newOrder() {
            this.transaction_success = false;
        }

    },
};
</script>

<style>
</style>