<template>
  <form class="">
    <div class="row mb-3" v-for="a in count" :key="a">
      <div class="col-md-2">
        <div class="mt-2">
          <FreeStyleShimmer :is-loading="true" height="15px" width="100px" />
        </div>
      </div>
      <div class="col-md-10">
        <FreeStyleShimmer :is-loading="true" height="35px" />
      </div>
    </div>
    <div class="float-end mt-2">
      <FreeStyleShimmer :is-loading="true" height="35px" width="100px" />
    </div>
  </form>
</template>

<script>
import { FreeStyleShimmer } from "vue3-shimmer";
export default {
  components: {
    FreeStyleShimmer,
  },
  name: "FormShimmer",
  props: {
    count: Number,
  },
};
</script>

<style>
</style>